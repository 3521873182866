.splide__track.splide__track--slide{
  margin: 0 46px;
}

.splide__arrow{
  background: transparent;
  svg {
    fill: #a3a3a3;
  }
}

.splide__track--nav>.splide__list>.splide__slide.is-active{
  border: 2px solid #e0e0e0;
  border-radius: 2px;
}

.thumbs-list{
  margin: 8px 0;
  display: flex;
  justify-content: center;
}

.img-container {
  display: flex;
  justify-content: center;

  img {
    max-height: 400px;
    // @media only screen and (max-width: 776px) {
    //     max-height: 335px;
    // }
  }
}